<div class="item-page-field">
  <ds-metadata-values *ngFor="let mdValue of item?.allMetadata(fields)"
    [mdValues]="[mdValue]"
    [separator]="separator"
    [label]="label + (mdValue?.language ? '_' + mdValue.language : '')"
    [enableMarkdown]="enableMarkdown"
    [urlRegex]="urlRegex"
    [browseDefinition]="browseDefinition|async"
  ></ds-metadata-values>
</div>
