<header>
    <div class="container">
      <div class="d-flex align-items-center flex-row justify-content-between">
        <span class="navbar-brand d-inline-flex align-items-center my-2 ">
          <a href="https://www.cp2.g12.br">
            <img src="assets/custom/images/logo-white.png" [attr.alt]="'menu.header.image.logo' | translate"/>
          </a>
          <a routerLink="/home">
            <img src="assets/custom/images/petrus_logo.png" class="ml-4" [attr.alt]="'menu.header.image.logo' | translate"/>
          </a>
        </span>
  
        <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="navbar navbar-light navbar-expand-md flex-shrink-0 px-0 flex-column">
          <div>
            <ds-themed-lang-switch></ds-themed-lang-switch>
            <ds-impersonate-navbar></ds-impersonate-navbar>
            <ds-context-help-toggle></ds-context-help-toggle>
            <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
            <div *ngIf="isXsOrSm$ | async" class="pl-2">
              <button class="navbar-toggler px-0" type="button" (click)="toggleNavbar()"
                      aria-controls="collapsingNav"
                      aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
                <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
              </button>
            </div>
          </div>
          <ds-themed-search-navbar></ds-themed-search-navbar>
        </nav>
      </div>
    </div>
  </header>
  