<div id="search-navbar-container" [title]="'nav.search' | translate">
	<form [formGroup]="searchForm" (ngSubmit)="onSubmit(searchForm.value)" autocomplete="on">
		<input #searchInput class="form-control" [attr.aria-label]="('nav.search' | translate)" name="query"
			formControlName="query" type="text" placeholder="{{searchExpanded ? ('nav.search' | translate) : ''}}"
			[tabIndex]="searchExpanded ? 0 : -1" [attr.data-test]="'header-search-box' | dsBrowserOnly" />
		<button [attr.aria-label]="'nav.search.button' | translate" type="button" (click)="onSubmit(searchForm.value)"
			[attr.data-test]="'header-search-icon' | dsBrowserOnly" class="btn btn-outline-dark">
			Ir
		</button>
	</form>
</div>