import { Component } from '@angular/core';
import { ThemedComponent } from '../../shared/theme-support/themed.component';
import { ExpandableBrazilNavbarSectionComponent } from './expandable-brazil-navbar-section.component';
import { rendersSectionForMenu } from '../../shared/menu/menu-section.decorator';
import { MenuID } from '../../shared/menu/menu-id.model';

/**
 * Themed wrapper for ExpandableNavbarSectionComponent
 */
@Component({
  selector: 'ds-themed-expandable-brazil-navbar-section',
  styleUrls: [],
  templateUrl: '../../shared/theme-support/themed.component.html',
})
@rendersSectionForMenu(MenuID.BRAZIL, true)
export class ThemedExpandableBrazilNavbarSectionComponent  extends ThemedComponent<ExpandableBrazilNavbarSectionComponent> {
  protected getComponentName(): string {
    return 'ExpandableBrazilNavbarSectionComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/brazil-navbar/expandable-brazil-navbar-section/expandable-brazil-navbar-section.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./expandable-brazil-navbar-section.component`);
  }
}
