import { getInfoModulePath } from '../app-routing-paths';

export const END_USER_AGREEMENT_PATH = 'end-user-agreement';
export const PRIVACY_PATH = 'privacy';
export const FEEDBACK_PATH = 'feedback';
export const FAQ_PATH = 'faq';
export const HELP_PATH = 'help';
export const AUTHOR_INSTRUCTIONS_PATH = 'author-instructions';
export const PRESENTATION_PATH = 'presentation';

export function getEndUserAgreementPath() {
    return getSubPath(END_USER_AGREEMENT_PATH);
}

export function getPrivacyPath() {
    return getSubPath(PRIVACY_PATH);
}

export function getFeedbackPath() {
    return getSubPath(FEEDBACK_PATH);
}

export function getFaqPath() {
    return getSubPath(FAQ_PATH);
}

export function getAuthorInstructionsPath() {
    return getSubPath(AUTHOR_INSTRUCTIONS_PATH);
}

export function getPresentationPath() {
    return getSubPath(PRESENTATION_PATH);
}

export function getHelpPath() {
    return getSubPath(HELP_PATH);
}

function getSubPath(path: string) {
    return `${getInfoModulePath()}/${path}`;
}
