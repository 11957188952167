import { Component, ElementRef, ViewChild } from '@angular/core';
import { SearchNavbarComponent as BaseComponent } from '../../../../app/search-navbar/search-navbar.component';

@Component({
  selector: 'ds-search-navbar',
  styleUrls: ['./search-navbar.component.scss'],
  templateUrl: './search-navbar.component.html',
  animations: []
})
export class SearchNavbarComponent extends BaseComponent {
}
