<footer class="text-lg-start">
  <div class="top-footer py-5">
    <!-- Grid container -->
    <div class="container">
      <!--Grid row-->
      <div class="row align-items-center">

        <!--Grid column-->
        <div class="col">
          <ul class="list-unstyled mb-0" style="line-height: 24px;">
            <li>
              <a class="text-white" href="javascript:void(0);" (click)="showCookieSettings()">
                {{ 'footer.link.cookies' | translate}}</a>
            </li>
<!--             <li *ngIf="showPrivacyPolicy">
              <a class="text-white" routerLink="info/privacy">
                {{ 'footer.link.privacy-policy' | translate}}</a>
            </li> -->
            <li *ngIf="showEndUserAgreement">
              <a class="text-white" routerLink="info/end-user-agreement">
                {{ 'footer.link.end-user-agreement' | translate}}</a>
            </li>
            <li>
              <a class="text-white" href="https://portalespiral.cp2.g12.br">
                {{ 'footer.link.imperial' | translate}}</a>
            </li>
            <li>
              <a class="text-white" href="https://www.cp2.g12.br/proreitoria/prodi/cpii_numeros">
                {{ 'footer.link.cp2_numbers' | translate}}</a>
            </li>
            <li *ngIf="showSendFeedback$ | async">
              <a class="text-white" routerLink="info/feedback">
                {{ 'footer.link.feedback' | translate}}</a>
            </li>
          </ul>
        </div>
        <!--Grid column-->
        <div class="col-auto">
          <div class="brands">
            <a href="https://www.instagram.com/cp2oficial/" target="_blank">
              <img src="assets/custom/images/icons/brands/instagram.svg"/>
            </a>
            <a href="https://www.youtube.com/@cp2oficial" target="_blank">
              <img src="assets/custom/images/icons/brands/youtube.svg"/>
            </a>
            <a href="https://www.facebook.com/cp2oficial?locale=pt_BR" target="_blank">
              <img src="assets/custom/images/icons/brands/facebook-f.svg"/>
            </a>
          </div>
        </div>
      </div>
      <!--Grid row-->
    </div>
  </div>

  <div class="mid-footer">
    <!-- Grid container -->
    <div class="container">
      <!--Grid row-->
      <div class="row align-items-center">
        <!--Grid column-->
        <div class="col-lg col-md-6 mb-4 mb-lg-0" id="cpii">
          <div class="d-flex align-items-center">
            <img src="assets/custom/images/logo_light.png" [attr.alt]="'menu.header.image.logo' | translate" />
            <div class="text-white ml-4">
              <h3 class="font-weight-bold">Colégio Pedro II</h3>
              <ul class="list-unstyled mb-0">
                <li>Campo de São Cristóvão, 177</li>
                <li>São Cristóvão - Rio de Janeiro - RJ</li>    
                <li>2163-5841</li>
                <li>20921-903</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-lg-auto col-md-6 mb-4 mb-lg-0" id="mec">
          <img src="assets/custom/images/qr_code_mec.svg" alt="Consulte aqui o cadastro da Instituição no Sistema e-MEC"/>
        </div>

        <div class="col-lg-auto col-md-6 mb-4 mb-lg-0" id="unesco">
          <a href="http://peaunesco.wixsite.com/website" target="_blank">
            <img src="assets/custom/images/unesco.svg" alt="UNESCO"/>
          </a>
        </div>

        <div class="col-lg-auto col-md-6 mb-4 mb-lg-0" id="acessoinformacao">
          <a href="http://www.acessoainformacao.gov.br/" target="_blank">
            <img src="assets/custom/images/acesso-a-informacao.svg" alt="Acesso à informação"/>
          </a>
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="bottom-footer">
    <div class="container">
      <!--Grid row-->
      <div class="row align-items-center">
        <!--Grid column-->
        <div class="col-lg-auto col-md-6 mb-4 mb-lg-0">
          <a href="http://www.rederio.br/" target="_blank">
            <img src="assets/custom/images/rederio-logo.png" alt="Rede RIO" />
          </a>
        </div>

        <div class="col-lg-auto col-md-6 mb-4 mb-lg-0">
          <a href="http://www.rnp.br/" target="_blank">
            <img src="assets/custom/images/rnp-logo.png" alt="'RNP - Rede Nacional de Ensino e Pesquisa" />
          </a>
        </div>

        <div class="col-lg text-right col-md-12 ml-lg-auto mb-4 mb-lg-0">
          {{ 'footer.copyright' | translate:{year: dateObj | date:'y'} }}
        </div>
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </div>
  </div>
  <!-- Copyright -->
</footer>