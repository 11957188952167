import { Component } from '@angular/core';
import { ThemedComponent } from '../shared/theme-support/themed.component';
import { BrazilNavbarComponent } from './brazil-navbar.component';

/**
 * Themed wrapper for NavbarComponent
 */
@Component({
  selector: 'ds-themed-brazil-navbar',
  styleUrls: [],
  templateUrl: '../shared/theme-support/themed.component.html',
})
export class ThemedBrazilNavbarComponent  extends ThemedComponent<BrazilNavbarComponent> {
  protected getComponentName(): string {
    return 'BrazilNavbarComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../themes/${themeName}/app/brazil-navbar/brazil-navbar.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./brazil-navbar.component`);
  }
}
